import { ResendCodeButtonProps } from "./props.type";
import { useResendCodeButton } from "./useResendCodeButton";
import Text from "@/components/Text";

const ResendCodeButton = ({
  disabled,
  onClick,
  ...props
}: ResendCodeButtonProps) => {
  const { color } = useResendCodeButton(disabled);

  return (
    <Text
      style={
        disabled
          ? { cursor: "default" }
          : { cursor: "pointer", textDecoration: "underline" }
      }
      color={color}
      {...props}
      onClick={(e) => {
        if (disabled) {
          return;
        }

        onClick?.(e);
      }}
    >
      Reenviar código
    </Text>
  );
};

export default ResendCodeButton;
