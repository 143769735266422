export type EmailValidationResponse = {
  isCognitoUser: boolean;
  isRealityUser: boolean;
};

export type SignUpPayload = {
  name: string;
  email: string;
  phoneNumber: string;
  birthDate: string;
  gender: Gender;
  state: string;
  city: string;
  resaleCode?: string;
};

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "others",
}

export type SignInResponse = {
  AuthenticationResult?: {
    AccessToken?: string;
    ExpiresIn?: number;
  };
};
