import Theme from "@/utils/theme";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { SummaryWrapper, UnskewWrapper } from "./styled-components";
import { ShapedAccordionProps } from "./props.type";
import "./styles.css";
import { useShapedAccordion } from "./useShapedAccordion";
import Spacer from "../Spacer";
import ChevronDown from "@/ui/icons/ChevronDown";

const ShapedAccordion = (props: ShapedAccordionProps) => {
  const { items = [], fixedIndex, summaryStyle } = props;
  const { handleChange, getExpandedValue } = useShapedAccordion(props);

  return (
    <Box mx="0.5rem">
      {items.map((item, index) => {
        const isExpanded = getExpandedValue(index);
        const isFixed = fixedIndex === index;

        if (item.hidden) return;

        return (
          <>
            {!isExpanded && <Spacer height="1rem" />}
            <Accordion
              key={`accordion-item-${index}`}
              sx={{
                boxShadow: "none",
                position: "inherit",
              }}
              expanded={isExpanded}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                expandIcon={
                  isFixed ? null : (
                    <UnskewWrapper>
                      <ChevronDown color={Theme.colors.brand.secondary} />
                    </UnskewWrapper>
                  )
                }
                style={{
                  border: `1px solid ${Theme.colors.neutral.mid}`,
                  borderRadius: "0.2rem",
                  boxShadow: isExpanded
                    ? "none"
                    : `0.1rem 0.2rem 0px 0.1rem ${
                        item.shadowColor || Theme.colors.brand.primary
                      }`,
                  backgroundColor: Theme.colors.background.secondary,
                  ...summaryStyle,
                }}
                className="custom_summary"
              >
                <SummaryWrapper>{item.summary}</SummaryWrapper>
              </AccordionSummary>

              <AccordionDetails
                style={{
                  border: `1px solid ${Theme.colors.neutral.mid}`,
                  boxShadow: `0rem 0.3rem ${
                    item.shadowColor || Theme.colors.brand.primary
                  }`,
                  borderRadius: "0px 0px 0.5rem 0.5rem",
                  padding: "0px",
                }}
                className="custom_details"
              >
                {item.details}
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </Box>
  );
};

export default ShapedAccordion;
