import { isValidDate } from "@/helpers/date.helper";
import { Gender } from "@/services/auth/types/auth.types";
import { realityInfo } from "@/utils/realityInfo";
import { fullNameRegex, fullPhoneNumberRegex } from "@/utils/regex";
import { isAfter } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "@/firebase.config";
import { firebaseSignup, signUp } from "@/services/auth";
import { useLoader } from "@/context/LoaderContext";
import {
  getEmailAvailability,
  getPhoneNumberAvailability,
} from "@/services/users";
import { useCountdown } from "@/hooks/useCountdown";
import { useNavigate } from "react-router-dom";

interface CustomWindow extends Window {
  confirmationResult?: ConfirmationResult | null;
  recaptchaVerifier?: RecaptchaVerifier;
  recaptchaWidgetId?: number;
}

declare const window: CustomWindow;

// Comes from Google reCAPTCHA V3 script included in the HTML file
declare const grecaptcha: any;

export const useSignup = () => {
  const loader = useLoader();
  const navigate = useNavigate();
  const { value: smsTimerValue, restart: restartTimer } = useCountdown();

  const [isOpenPhoneNumberDialog, setIsOpenPhoneNumberDialog] = useState(true);
  const [isOpenAlreadyRegistered, setIsOpenAlreadyRegistered] = useState(false);
  const [isOpenChooseValidation, setIsOpenChooseValidation] = useState(false);
  const [isOpenSignUp, setIsOpenSignUp] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);

  const [email, setEmail] = useState("");
  const [emailConfirmation, setEmailConfirmation] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState<Gender>();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [confirmWorker, setConfirmWorker] = useState(false);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [resaleCode, setResaleCode] = useState<string>();
  const [otp, setOtp] = useState("");
  const [isVisibleResaleCodeTooltip, setIsVisibleResaleCodeTooltip] =
    useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const rawPhoneNumber = useMemo(() => {
    if (!phoneNumber) return "";

    return "+55" + phoneNumber.replace(/[^\d]/g, "");
  }, [phoneNumber]);

  const phoneNumberError = useMemo(() => {
    if (rawPhoneNumber?.length === 0) return "";

    const isValid = fullPhoneNumberRegex.test(rawPhoneNumber);

    if (!isValid) {
      return "Número de telefone inválido.";
    }

    return "";
  }, [rawPhoneNumber]);

  const emailConfirmationError = useMemo(() => {
    if (email?.length === 0 || emailConfirmation?.length === 0) return "";

    if (email !== emailConfirmation) {
      return "Os e-mails devem ser iguais.";
    }

    return "";
  }, [email, emailConfirmation]);

  const nameError = useMemo(() => {
    if (!name) return "";

    const isValid = fullNameRegex.test(name);

    if (!isValid) {
      return "Nome incompleto.";
    }

    return "";
  }, [name]);

  const rawBirthdate = useMemo(() => {
    if (!birthDate) return "";

    const [day, month, year] = birthDate.split("/");

    return `${year}-${month}-${day}`;
  }, [birthDate]);

  const birthDateError = useMemo(() => {
    if (rawBirthdate?.length === 0) return "";

    if (!isValidDate(rawBirthdate) || isAfter(rawBirthdate, new Date())) {
      return "Data inválida.";
    }

    return "";
  }, [rawBirthdate]);

  const isValid = useMemo(() => {
    return (
      !!email &&
      !!name &&
      !nameError &&
      !!rawPhoneNumber &&
      !phoneNumberError &&
      !!rawBirthdate &&
      !birthDateError &&
      !!gender &&
      !!selectedState &&
      !!selectedCity &&
      confirmWorker &&
      confirmTerms
    );
  }, [
    email,
    name,
    nameError,
    phoneNumberError,
    birthDateError,
    gender,
    selectedState,
    selectedCity,
    confirmWorker,
    confirmTerms,
  ]);

  const buttonText = useMemo(() => {
    if (realityInfo.showResaleCode && currentStep === 1) {
      return "Inscreva-se";
    }

    if (realityInfo.showResaleCode) {
      return "Finalizar inscrição com o código";
    }

    return "Inscreva-se";
  }, [realityInfo.showResaleCode, currentStep]);

  const goBack = () => {
    window.history.back();
  };

  const goToSignIn = () => {
    navigate(`/sign-in`);
  };

  const onValidatePhone = async () => {
    const isValidPhone =
      phoneNumberError.length === 0 && rawPhoneNumber.length > 0;
    if (!isValidPhone) return;

    try {
      setIsLoadingButton(true);

      const { isAvailable } = await getPhoneNumberAvailability(rawPhoneNumber);
      setIsOpenPhoneNumberDialog(false);

      if (isAvailable) {
        return setIsOpenSignUp(true);
      }

      setIsOpenAlreadyRegistered(true);
    } catch (err) {
      console.error("Error validating phone", err);
      toast("Erro validando telefone", { type: "error" });
    } finally {
      setIsLoadingButton(false);
    }
  };

  const closeSignupDialog = () => {
    setIsOpenSignUp(false);
  };

  const toggleIsVisibleResaleCodeTooltip = () => {
    setIsVisibleResaleCodeTooltip((old) => !old);
  };

  const verifyEmailAvailability = async () => {
    try {
      loader.show("verify-email");
      const { isAvailable } = await getEmailAvailability(email);

      if (!isAvailable) {
        toast("E-mail já cadastrado", { type: "error" });
        return false;
      }

      return true;
    } catch (err) {
      toast("Erro verificando e-mail", { type: "error" });
      console.error("Erro verificando e-mail");
    } finally {
      loader.hide("verify-email");
    }
  };

  const onPressButton = async () => {
    if (!isValid) {
      return toast("Preencha todos os campos corretamente", { type: "error" });
    }

    const isAvailableEmail = await verifyEmailAvailability();
    if (!isAvailableEmail) return;

    if (currentStep === 1) {
      return setIsOpenChooseValidation(true);
    }

    if (otp.length < 6) {
      return setIsInvalidCode(true);
    }

    try {
      loader.show("verify_code");
      setIsInvalidCode(false);

      await window.confirmationResult?.confirm(otp);
    } catch (error) {
      toast("Código inválido ou inexistente", { type: "error" });
      console.error("Código inválido ou inexistente");
      resetReCaptcha();
      return setIsInvalidCode(true);
    } finally {
      loader.hide("verify_code");
    }

    try {
      loader.show("sign_up");
      await signUp({
        email,
        name,
        phoneNumber: rawPhoneNumber,
        birthDate: rawBirthdate,
        gender: gender!,
        state: selectedState,
        city: selectedCity,
      });

      //force login
      auth.signOut();
      goToSignIn();
    } catch (err) {
      toast("Erro criando a conta", { type: "error" });
      console.error("Erro criando a conta");
    } finally {
      loader.hide("sign_up");
    }
  };

  const onSendSmsCode = async () => {
    if (!!phoneNumberError || !rawPhoneNumber) {
      return;
    }

    try {
      loader.show("verify_number");

      const { isAvailable } = await getPhoneNumberAvailability(rawPhoneNumber);
      if (!isAvailable) {
        return setIsOpenAlreadyRegistered(true);
      }

      await firebaseSignup(rawPhoneNumber);
    } catch (error) {
      console.error("Erro ao enviar código", error);
    } finally {
      loader.hide("verify_number");
    }

    if (window.recaptchaWidgetId !== undefined) {
      const widgetId = await window.recaptchaVerifier!.render();
      window.recaptchaWidgetId = widgetId;
    }

    sendSignInSmsCode();
  };

  const sendSignInSmsCode = async () => {
    loader.show("sign_in");
    const appVerifier = window.recaptchaVerifier;

    if (!rawPhoneNumber.length) return;

    signInWithPhoneNumber(auth, rawPhoneNumber, appVerifier!)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;

        setCurrentStep(2);
        restartTimer();
        setIsOpenChooseValidation(false);
      })
      .catch((error) => {
        console.error("Erro ao enviar código", error);
        toast("Erro ao enviar código", { type: "error" });
        setIsOpenChooseValidation(false);
        resetReCaptcha();
      })
      .finally(() => loader.hide("sign_in"));
  };

  const resetReCaptcha = () => {
    if (
      typeof grecaptcha !== "undefined" &&
      typeof window.recaptchaWidgetId !== "undefined"
    ) {
      grecaptcha.reset(window.recaptchaWidgetId);
    }
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (_response: any) {
          sendSignInSmsCode();
        },
      }
    );
  }, []);

  useEffect(() => {
    resetReCaptcha();
  }, [rawPhoneNumber]);

  return {
    isOpenPhoneNumberDialog,
    setIsOpenPhoneNumberDialog,
    isOpenChooseValidation,
    setIsOpenChooseValidation,
    isOpenSignUp,
    goBack,
    goToSignIn,
    onValidatePhone,
    closeSignupDialog,
    isLoadingButton,
    currentStep,
    setCurrentStep,

    email,
    setEmail,
    emailConfirmation,
    setEmailConfirmation,
    emailConfirmationError,
    name,
    setName,
    nameError,
    phoneNumber,
    setPhoneNumber,
    phoneNumberError,
    birthDate,
    setBirthDate,
    birthDateError,
    gender,
    setGender,
    selectedState,
    setSelectedState,
    selectedCity,
    setSelectedCity,
    otp,
    setOtp,
    isInvalidCode,
    setIsInvalidCode,
    smsTimerValue,

    confirmWorker,
    setConfirmWorker,
    confirmTerms,
    setConfirmTerms,

    resaleCode,
    setResaleCode,
    isVisibleResaleCodeTooltip,
    toggleIsVisibleResaleCodeTooltip,

    buttonText,
    onPressButton,
    sendSignInSmsCode,

    isOpenAlreadyRegistered,
    setIsOpenAlreadyRegistered,
    onSendSmsCode,
    isValid,
  };
};
