// Password
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?]).{8,}$/;

// Email
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;

// Phone number
export const phoneNumberRegex = /^\d{11,}$/;

// Full Phone number with country code
export const fullPhoneNumberRegex = /^\+\d{12,13}$/;

// Full name
export const fullNameRegex = /^[^\s]+\s+[^\s]+.*$/;
