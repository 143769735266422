import Theme from "@/utils/theme";
import OTPInput, { OTPInputProps } from "react-otp-input";

export const OtpCodeInput = ({
  isInvalid,
  onSubmit,
  ...props
}: { isInvalid: boolean; onSubmit: () => void } & Omit<
  OTPInputProps,
  "renderInput"
>) => (
  <OTPInput
    numInputs={6}
    inputStyle={{
      width: "2.5rem",
      height: "3rem",
      margin: "0px 0.25rem",
      borderRadius: "0.5rem",
      border: `1px solid ${
        isInvalid ? Theme.colors.feedback.negative : Theme.colors.text.secondary
      }`,
      WebkitAppearance: "none",
      MozAppearance: "textfield",
    }}
    {...props}
    renderInput={(_props) => (
      <input
        {..._props}
        inputMode="numeric"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            return onSubmit();
          }

          _props.onKeyDown(e);
        }}
      />
    )}
  />
);
