import { useMemo, useState } from "react";
import { QuizDetailsDialogProps } from "./props.type";
import { ChallengeType, QuizSubmissionAnswerDto } from "@/services/challenges/types/challenges.types";
import { toast } from "react-toastify";
import {
  submitQuizChallenge,
  getQuizChallengeInfo,
} from "@/services/challenges/quizChallenges";
import { useParams } from "react-router-dom";

export const useQuizQuestionsDialog = ({
  challenge,
  onClose,
}: Partial<QuizDetailsDialogProps>) => {
  const { id } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<QuizSubmissionAnswerDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenLeaveDialog, setIsOpenLeaveDialog] = useState(false);
  const toggleLeaveDialog = () => setIsOpenLeaveDialog(!isOpenLeaveDialog);
  const [showResumeCase, setResumeCase] = useState(false);
  const toggleResumeCase = () => setResumeCase(false);

  const [updatedChallenge, setUpdatedChallenge] = useState<
    ChallengeType | undefined
  >(challenge);

  const currentQuestion = useMemo(() => {
    return challenge?.questions?.[currentQuestionIndex];
  }, [challenge?.questions, currentQuestionIndex]);

  const currentAlternatives = useMemo(() => {
    return currentQuestion?.alternatives ?? [];
  }, [currentQuestion]);

  const currentAnswer = useMemo(() => {
    if (!currentQuestion) return;

    return answers.find((a) => a.questionId === currentQuestion.id);
  }, [currentQuestion, answers]);

  const isLastQuestion = useMemo(
    () => currentQuestionIndex >= (challenge?.questions?.length ?? 0) - 1,
    [currentQuestionIndex, challenge?.questions]
  );

  const totalCorrect = useMemo(() => {
    return challenge?.submission?.totalCorrect ?? 0;
  }, [challenge?.submission]);

  const totalWrong = useMemo(() => {
    return (challenge?.questions?.length ?? 0) - totalCorrect;
  }, [challenge?.questions, totalCorrect]);

  const setCurrentQuestionAnswer = (questionAlternativeId: number) => {
    if (!currentQuestion) return;

    const filteredAnswers = answers.filter(
      ({ questionId }) => questionId !== currentQuestion.id
    );
    setAnswers([
      ...filteredAnswers,
      { questionId: currentQuestion.id, questionAlternativeId },
    ]);
  };

  const getIsSelected = (id: number) => {
    return currentAnswer?.questionAlternativeId === id;
  };

  const onLeaveQuiz = () => {
    toggleLeaveDialog();
    onClose?.();

    setAnswers([]);
    setCurrentQuestionIndex(0);
  };

  const onPreviousQuestion = () =>
    setCurrentQuestionIndex(currentQuestionIndex - 1);

  const onNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const onSubmit = async () => {
    try {
      if (!id) return;

      if (challenge?.status === "Finished") {
        return;
      }
      setIsLoading(true);

      await submitQuizChallenge({ id: Number(id), answers });

      // await queryClient.invalidateQueries({
      //   queryKey: ["stage-challenges", challenge?.stageId],
      // });
      // await queryClient.invalidateQueries({
      //   queryKey: "challenge-details",
      //   exact: false,
      // });
      
      const data = await getQuizChallengeInfo(Number(id));
      setUpdatedChallenge(data);
      setResumeCase(true);
    } catch (err) {
      console.error("Error submitting challenge", err);
      toast("Erro ao enviar desafio", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    currentQuestionIndex,
    currentQuestion,
    isLastQuestion,

    currentAlternatives,
    currentAnswer,
    answers,
    getIsSelected,
    setCurrentQuestionAnswer,

    isOpenLeaveDialog,
    toggleLeaveDialog,

    showResumeCase,
    toggleResumeCase,

    onLeaveQuiz,
    onPreviousQuestion,
    onNextQuestion,
    updatedChallenge,
    onSubmit,
    isLoading,
    totalCorrect,
    totalWrong,
  };
};
