import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "@/pages/Dashboard";
import { ThemeProvider } from "styled-components";
import Theme from "./utils/theme";
import { PageWrapper } from "./layout/PageWrapper";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Profile from "./pages/Profile";
import { UserProvider } from "./context/UserContext";
import { ProtectedRoutesProvider } from "./context/ProtectRoutesContext";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordRecovery from "./pages/PasswordRecovery";
import Challenges from "./pages/Challenges";
import Courses from "./pages/Courses";
import ChallengeSubmission from "./pages/ChallengeSubmission";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/react-query";
import { ProfileInfoProvider } from "./context/ProfileInfoContext";
import { StagesProvider } from "./context/StagesContext";
import { RealityProvider } from "./context/RealityContext";
import TagManager from "react-gtm-module";
import { realityInfo } from "./utils/realityInfo";
import Regulation from "./pages/Regulation";
import { LocalStorageProvider } from "./context/LocalStorageContext";
import { SessionDialogsProvider } from "./context/SessionDialogsContext";
import { LoaderProvider } from "./context/LoaderContext";

TagManager.initialize({
  gtmId: realityInfo.gtmId,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <LoaderProvider>
            <RealityProvider>
              <LocalStorageProvider>
                <UserProvider>
                  <StagesProvider>
                    <SessionDialogsProvider>
                      <ProfileInfoProvider>
                        <ProtectedRoutesProvider>
                          <PageWrapper>
                            <Routes>
                              <Route
                                path="/dashboard"
                                element={<Dashboard />}
                              />
                              <Route path="/profile" element={<Profile />} />
                              <Route
                                path="/challenges"
                                element={<Challenges />}
                              />
                              <Route
                                path="/challenges/:type/:id"
                                element={<ChallengeSubmission />}
                              />

                              <Route path="/courses" element={<Courses />} />
                              <Route
                                path="/regulation"
                                element={<Regulation />}
                              />

                              <Route path="/sign-up" element={<SignUp />} />
                              <Route path="/sign-in" element={<SignIn />} />
                              <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                              />
                              <Route
                                path="/password-recovery"
                                element={<PasswordRecovery />}
                              />
                            </Routes>
                          </PageWrapper>
                        </ProtectedRoutesProvider>
                      </ProfileInfoProvider>
                    </SessionDialogsProvider>
                  </StagesProvider>
                </UserProvider>
              </LocalStorageProvider>
            </RealityProvider>
          </LoaderProvider>

          <ToastContainer />
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
