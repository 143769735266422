import { PhoneNumberDialogProps } from "./props.type";
import Dialog from "@/components/Dialog";
import PhoneInput from "@/components/PhoneInput";

// TODO: rename
const PhoneNumberDialog = (props: PhoneNumberDialogProps) => {
  return (
    <Dialog
      {...props}
      maxWidth="33rem"
      text={`Atenção, mestre!\nPara iniciar sua inscrição, precisamos verificar seu telefone. Insira abaixo um telefone válido.`}
      content={
        <PhoneInput
          ContainerProps={{ style: { width: "100%" }, mb: "1.5rem" }}
          value={props.email}
          setValue={props.setEmail}
          label="Telefone"
          error={!!props.error}
          errorText={props.error}
        />
      }
      confirmButtonText="Continuar Inscrição"
      closeButtonText="Sair"
    />
  );
};

export default PhoneNumberDialog;
