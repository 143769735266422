import { createContext, useContext, useMemo, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import Theme from "@/utils/theme";

type LoaderContext = {
  show: (val: string) => void;
  hide: (val: string) => void;
};

const LoaderContext = createContext<LoaderContext>({} as LoaderContext);

type LoaderProviderProps = {
  children: React.ReactNode;
};

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const [loadingIds, setLoadingIds] = useState<string[]>([]);

  const isLoading = useMemo(() => {
    return loadingIds.length > 0;
  }, [loadingIds]);

  const show = (val: string) => {
    setLoadingIds((current) => [...current, val]);
  };

  const hide = (val: string) => {
    setLoadingIds((current) => current.filter((id) => id !== val));
  };

  return (
    <LoaderContext.Provider value={{ show, hide }}>
      <Backdrop
        sx={{
          color: Theme.colors.brand.primary,
          zIndex: (theme) => theme.zIndex.tooltip + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = (): LoaderContext => useContext(LoaderContext);
