import Text from "@/components/Text";
import Theme from "@/utils/theme";
import { useQuizChallengeSubmission } from "./useQuizChallengeSubmission";
import ShapedAccordion from "@/components/ShapedAccordion";
import { Box, CircularProgress } from "@mui/material";
import Spacer from "@/components/Spacer";
import Button from "@/components/Button";
import ChallengeSubmissionWrapper from "@/layout/ChallengeSubmissionWrapper";
import { OutlinedBox } from "./styles";
import QuizIcon from "/assets/icons/quiz.svg";
import QuizQuestionsDialog from "../QuizQuestionsDialog";
import { useQuizQuestionsDialog } from "../QuizQuestionsDialog/useQuizQuestionsDialog";

// TODO: verificar como fica dps que etapa termina
function QuizChallengeSubmission() {
  const {
    challenge,
    setChallenge,
    challengeStatus,
    quizStatus,

    toggleQuiz,
    isOpenQuiz,
  } = useQuizChallengeSubmission();

  const { isLoading } = useQuizQuestionsDialog({
    challenge,
    onClose: toggleQuiz,
  });

  return (
    <ChallengeSubmissionWrapper type="quiz" onLoadChallenge={setChallenge}>
      <ShapedAccordion
        fixedIndex={0}
        items={[
          {
            summary: (
              <Box display="flex">
                <Text>Case</Text>
                <Text color={Theme.colors.neutral.mid}> | </Text>
                <Text color={challengeStatus.color}>
                  {challengeStatus.text}
                </Text>
              </Box>
            ),
            details: isLoading ? (
              <CircularProgress
                style={{ color: Theme.colors.brand.secondary }}
                size="1.5rem"
              />
            ) : (
              <Box display="flex" flexDirection="column" p="1.5rem" flex={1}>
                <OutlinedBox>
                  <img src={QuizIcon} />
                  <Text color={quizStatus.color} ml="1rem">
                    {quizStatus.text}
                  </Text>

                  <Spacer grow />

                  <Button
                    variant="outlined"
                    fullWidth={false}
                    onClick={toggleQuiz}
                    style={{ padding: "0.2rem 1rem" }}
                  >
                    {!!challenge?.submission ? "Detalhes" : "Iniciar Case"}
                    <Spacer width="0.5rem" />
                    {">"}
                  </Button>
                </OutlinedBox>
              </Box>
            ),
          },
        ]}
      />

      <QuizQuestionsDialog
        isOpen={isOpenQuiz}
        challenge={challenge!}
        onClose={toggleQuiz}
      />
    </ChallengeSubmissionWrapper>
  );
}

export default QuizChallengeSubmission;
