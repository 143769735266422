//@ts-nocheck
import CardWithShadow from "@/components/CardWithShadow";
import Text from "@/components/Text";
import Theme from "@/utils/theme";
import { useChallengeSubmissionWrapper } from "./useChallengeSubmissionWrapper";
import { Box, CircularProgress, Stack } from "@mui/material";
import Spacer from "@/components/Spacer";
import {
  FullscreenButton,
  MediaTutorialWrapper,
  OutlinedBox,
  QuizTextBox,
  TutorialMedia,
} from "./styles";
import IconButton from "@/components/IconButton";
import Divider from "@/components/Divider";
import { Lightbox } from "react-modal-image";
import { ChallengeSubmissionWrapperProps } from "./props.type";
import InfoIcon from "@/ui/icons/InfoIcon";
import PointsProgress from "./PointsProgress";

import selfieTutorial from "/assets/selfie-tutorial.png";

function ChallengeSubmissionWrapper({
  children,
  type = "media",
  onClickBackOverwrite,
  onLoadChallenge,
}: ChallengeSubmissionWrapperProps) {
  const {
    challenge,
    challengeStatus,

    isFullscreenImage,
    toggleFullscreen,
    cardTitle,

    onClickBack,
  } = useChallengeSubmissionWrapper({
    onClickBackOverwrite,
    onLoadChallenge,
    type,
  });

  if (!challenge) {
    return (
      <Box display="flex" flex={1} justifyContent="center">
        <CircularProgress
          style={{ color: Theme.colors.brand.secondary }}
          size="1.5rem"
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <CardWithShadow
        flexDirection="row"
        alignItems="center"
        maxWidth="max-content"
        alignSelf={{ xs: "center", lg: "inherit" }}
      >
        <Stack
          p="1rem"
          flexDirection="row"
          alignItems="center"
          borderRight="1px solid"
          borderColor={Theme.colors.neutral.light}
        >
          <IconButton icon="back" onClick={onClickBack} />

          <Text fontSize="1rem" ml="0.5rem">
            DESAFIO {challenge.order}
          </Text>
        </Stack>

        <Stack
          flexDirection="row"
          p="1rem"
          gap="1rem"
          display={{ xs: "none", lg: "flex" }}
        >
          <OutlinedBox position="relative">
            <Text>Status: </Text>
            <Text color={challengeStatus.color}>{challengeStatus.text}</Text>
          </OutlinedBox>

          <PointsProgress
            points={challenge.points.earnedPoints}
            maxPoints={challenge.points.maximumPoints}
          />
        </Stack>
      </CardWithShadow>

      <CardWithShadow
        flexDirection="row"
        alignItems="center"
        maxWidth="max-content"
        mt="1rem"
        alignSelf={{ xs: "center", lg: "inherit" }}
        display={{ xs: "flex", lg: "none" }}
      >
        <Stack flexDirection="row" p="1rem" gap="1rem">
          <OutlinedBox position="relative">
            <Text>Status: </Text>
            <Text color={challengeStatus.color}>{challengeStatus.text}</Text>
          </OutlinedBox>

          <PointsProgress
            points={challenge.points.earnedPoints}
            maxPoints={challenge.points.maximumPoints}
          />
        </Stack>
      </CardWithShadow>

      <CardWithShadow mt="0.5rem">
        <CardWithShadow alignItems="center" p="1rem" border="none">
          <Text color={Theme.colors.neutral.dark} fontSize="0.9rem">
            {challenge.title}
          </Text>

          <Divider margin="0.5rem" />

          <Text color={Theme.colors.neutral.dark} fontSize="0.8rem">
            {challenge.description}
          </Text>

          {type === "quiz" && (
            <QuizTextBox>
              <InfoIcon size={24} />

              <Text
                align="center"
                color="#3C4044"
                fontSize="1rem"
                p="0rem 1.5rem"
                fontWeight={300}
                mt="0.5rem"
              >
                Clique no botão "Iniciar Case" e responda todas as perguntas. Ao
                finalizar, clique em "Enviar" e veja sua pontuação obtida. Faça
                com atenção, pois não será possível realizar uma nova tentativa.
                Boa sorte!
              </Text>
            </QuizTextBox>
          )}
        </CardWithShadow>

        <Box
          alignItems="center"
          justifyContent="center"
          py="0.5rem"
          position="relative"
          display={type === "media" ? "flex" : "none"}
        >
          <MediaTutorialWrapper onClick={toggleFullscreen}>
            <TutorialMedia src={challenge.mediaTutorial || selfieTutorial} />
            {isFullscreenImage && (
              <Lightbox
                small=""
                medium={challenge.mediaTutorial}
                alt={challenge.title}
                hideDownload
                hideZoom
                imageBackgroundColor="transparent"
                onClose={toggleFullscreen}
              />
            )}
            <FullscreenButton />
          </MediaTutorialWrapper>
        </Box>
      </CardWithShadow>

      <CardWithShadow p={{ xs: "1rem 0rem", sm: "1rem 1.5rem" }} mt="1rem">
        <Text
          color={Theme.colors.neutral.dark}
          ml={{ xs: "1.5rem", sm: "0px" }}
        >
          {cardTitle}
        </Text>

        <Spacer height="1.5rem" />
        {children}
      </CardWithShadow>
    </Box>
  );
}

export default ChallengeSubmissionWrapper;
