import { createContext, useEffect } from "react";
import { useUser } from "./UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import Theme from "@/utils/theme";

type ProtectedRoutes = {};

const ProtectedRoutes = createContext<ProtectedRoutes>({} as ProtectedRoutes);

type ProtectedRoutesProviderProps = {
  children: React.ReactNode;
};

export const noAuthRequired = [
  "/sign-in",
  "/sign-up",
  "/forgot-password",
  "/password-recovery",
];

export const ProtectedRoutesProvider = ({
  children,
}: ProtectedRoutesProviderProps) => {
  const { userInfo, isAuthenticated } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      noAuthRequired.includes(location.pathname) ||
      isAuthenticated === undefined
    ) {
      return;
    }

    if (!isAuthenticated) {
      toast("Faça login para acessar a página", { type: "warning" });
      navigate("/sign-in");
    }
    // if (!accessToken) {
    //   toast("Faça login para acessar a página", { type: "warning" });
    //   navigate("/sign-in");
    // } else if (isTokenExpired) {
    //   toast("Seu acesso expirou, faça login novamente para acessar a página.", {
    //     type: "warning",
    //   });
    //   navigate("/sign-in");
    // }
  }, [isAuthenticated, location.pathname]);

  if (noAuthRequired.includes(location.pathname))
    return (
      <ProtectedRoutes.Provider value={{}}>{children}</ProtectedRoutes.Provider>
    );

  return (
    <ProtectedRoutes.Provider value={{}}>
      <>
        {!!userInfo ? (
          children
        ) : (
          <Box
            bgcolor={Theme.colors.background.secondary}
            display="flex"
            flex={1}
            height="100vh"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress style={{ color: Theme.colors.brand.secondary }} />
          </Box>
        )}
      </>
    </ProtectedRoutes.Provider>
  );
};
