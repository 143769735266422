import { memo, useState, useRef } from "react";
import { Box, Card, CircularProgress } from "@mui/material";
import { QuizDetailsDialogProps } from "./props.type";
import Text from "@/components/Text";
import { useQuizQuestionsDialog } from "./useQuizQuestionsDialog";
import PointsSlider from "@/components/PointsSlider";
import Theme from "@/utils/theme";
import {
  AlternativeBullet,
  AlternativeWrapper,
  // QuestionNumberWrapper,
  // QuestionWrapper,
} from "./styles";
import Button from "@/components/Button";
import Spacer from "@/components/Spacer";
import Modal from "@/components/Modal";
import Dialog from "@/components/Dialog";
import QuizPointsDialog from "./components/QuizPointsDialog";
import InfoIcon from "@/ui/icons/InfoIcon";
import HighlightedText from "@/components/HighlightedText";
import {
  FullscreenButton,
  MediaTutorialWrapper,
  TutorialMedia,
} from "@/layout/ChallengeSubmissionWrapper/styles";
import FsLightbox from "fslightbox-react";
import { useTimer } from "react-timer-hook";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import Divider from "@mui/material/Divider";

const QuizQuestionsDialog = (props: QuizDetailsDialogProps) => {
  const { isOpen, challenge, onClose } = props;

  const [step, setStep] = useState(0);
  const [isFullscreenImage, setIsFullscreenImage] = useState(false);
  const toggleFullscreen = () => setIsFullscreenImage(!isFullscreenImage);
  const [isProgressCase, setIsProgressCase] = useState(false);

  const [isTimeExpired, setIsTimeExpired] = useState(false);

  const timeLeftRef = useRef<Date>(new Date());

  const {
    // currentQuestionIndex,
    currentQuestion,
    isLastQuestion,

    currentAlternatives,
    currentAnswer,
    answers,
    getIsSelected,
    setCurrentQuestionAnswer,

    isOpenLeaveDialog,
    toggleLeaveDialog,
    updatedChallenge,
    onLeaveQuiz,
    // onPreviousQuestion,
    onNextQuestion,

    showResumeCase,
    toggleResumeCase,

    onSubmit,
    isLoading,
    totalCorrect,
    totalWrong,
  } = useQuizQuestionsDialog(props);

  const { seconds, minutes, restart, pause } = useTimer({
    autoStart: false,
    expiryTimestamp: timeLeftRef.current,
    onExpire: () => {
      console.warn("onExpire called");
      onSubmit();
      setIsTimeExpired(true);
    },
  });

  if (challenge?.submission) {
    return (
      <QuizPointsDialog
        isOpen={isOpen}
        onClose={onClose}
        challenge={challenge}
        totalCorrect={totalCorrect}
        totalWrong={totalWrong}
      />
    );
  }

  return (
    <>
      <FsLightbox
        toggler={isFullscreenImage}
        sources={
          currentQuestion?.questionImage ? [currentQuestion.questionImage] : []
        }
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        containerProps={{
          width: { xs: "100%", sm: "70vw" },
          overflow: "auto",
          height: "100%",
        }}
      >
        <>
          {step === 0 && (
            <Box
              py="1.5rem"
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text fontSize="1rem" mb="1.5rem">
                CASE - {challenge?.title}
              </Text>

              <Card
                variant="outlined"
                style={{
                  marginBottom: "1rem",
                  borderRadius: "0.5rem",
                  border: "none",
                  margin: "1rem",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Box
                    display="flex"
                    py="0.5rem"
                    px="1rem"
                    sx={{
                      border: `1px solid ${Theme.colors.feedback.negative}`,
                      borderRadius: "0.5rem",
                    }}
                  >
                    <Box sx={{ display: "flex" }} alignItems="center">
                      <InfoIcon
                        size={20}
                        color={Theme.colors.feedback.negative}
                      />

                      <HighlightedText
                        fontWeight={400}
                        hightlightWeight={400}
                        ml={1}
                        text={` Você terá: {{20 minutos}} para completar esse desafio. Clique em {{Iniciar}} quando estiver pronto.`}
                        color={Theme.colors.text.tertiary}
                        highlightColor={Theme.colors.feedback.negative}
                      />
                    </Box>
                  </Box>

                  <Card
                    variant="outlined"
                    style={{ padding: "1rem", borderRadius: "0.5rem" }}
                  >
                    {challenge.description}
                  </Card>
                </div>
              </Card>

              <Box
                alignItems="center"
                justifyContent="center"
                py="0.5rem"
                position="relative"
              >
                {currentQuestion?.questionImage && (
                  <MediaTutorialWrapper onClick={toggleFullscreen}>
                    <TutorialMedia src={currentQuestion?.questionImage} />
                    <FullscreenButton />
                  </MediaTutorialWrapper>
                )}
              </Box>

              <Box
                display="flex"
                width={"100%"}
                alignItems="center"
                mt="1.5rem"
                justifyContent={{ xs: "space-around" }}
              >
                <Button
                  variant="text"
                  fullWidth={false}
                  style={{
                    padding: "0.3rem",
                    width: "8rem",
                    color: Theme.colors.feedback.negative,
                  }}
                  onClick={onClose}
                  color="error"
                >
                  Sair do case
                </Button>

                <Spacer width="1rem" />
                <Button
                  fullWidth={false}
                  style={{ padding: "0.3rem", width: "8rem" }}
                  onClick={() => {
                    setStep(1);
                    const time = new Date();
                    time.setMinutes(time.getMinutes() + 20);
                    // time.setSeconds(time.getSeconds() + 20);
                    timeLeftRef.current = time;
                    if (!isProgressCase) {
                      setIsProgressCase(true);
                      restart(timeLeftRef.current);
                    }
                  }}
                >
                  Iniciar Case
                </Button>
              </Box>
            </Box>
          )}

          {step === 1 && (
            <Box
              pt="1.5rem"
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text fontSize="1rem" mb="1.5rem">
                CASE {challenge.title}
              </Text>

              <Box
                sx={{ p: 2 }}
                display="flex"
                justifyContent="center"
                border={"1px solid #E0E0E0"}
                borderRadius={4}
                mb={1}
              >
                <TimerOutlinedIcon sx={{ color: "#005EB8" }} />
                <Box ml={1} display="flex" alignItems="center">
                  Tempo Restante:{" "}
                  <Text color="#005EB8" ml={1}>
                    {minutes}m {seconds}s{" "}
                  </Text>
                </Box>
              </Box>
              <Box width="90%">
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    maxWidth="35rem"
                    width="100%"
                    border={"1px solid #E0E0E0"}
                    p={2}
                    sx={{
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    }}
                  >
                    <Text>{challenge.title}</Text>
                  </Box>

                  <Box width="100%" display={"flex"}>
                    {currentQuestion?.questionImage && (
                      <MediaTutorialWrapper
                        onClick={toggleFullscreen}
                        sx={{ width: "100%", height: "auto" }}
                      >
                        <TutorialMedia
                          width={"100%"}
                          style={{
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                          }}
                          src={currentQuestion?.questionImage}
                        />
                        <FullscreenButton />
                      </MediaTutorialWrapper>
                    )}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="start">
                  <Text>PERGUNTA:</Text>
                </Box>

                <Box display="flex" pt="1rem" justifyContent="start">
                  <Text fontWeight={"bold"}>{currentQuestion?.question}</Text>
                </Box>

                <Text color="#515961" fontWeight={300} mt="1rem">
                  Opções de resposta:
                </Text>

                {currentAlternatives.map(({ id, answer }) => (
                  <AlternativeWrapper
                    isSelected={getIsSelected(id)}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }

                      setCurrentQuestionAnswer(id);
                    }}
                  >
                    <AlternativeBullet isSelected={getIsSelected(id)} />
                    <Text>{answer}</Text>
                  </AlternativeWrapper>
                ))}

                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  justifyContent={{ xs: "space-between", sm: "space-between" }}
                  my="2.5rem"
                  gap={{ xs: "1rem", sm: "1rem" }}
                >
                  <Box width={{ xs: "100%", sm: "60%" }}>
                    <PointsSlider
                      currentPoints={answers.length}
                      totalPoints={challenge.questions?.length ?? 1}
                      usePercentage
                    />
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    mb="0.5rem"
                    width={{ xs: "100%", sm: "8rem" }}
                  >
                    <Button
                      fullWidth={false}
                      style={{ padding: "0.3rem", width: "100%" }}
                      onClick={
                        isLastQuestion
                          ? () => {
                              onSubmit();
                              pause();
                              onClose?.();
                            }
                          : () => onNextQuestion()
                      }
                      disabled={!currentAnswer || isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size="1.5rem"
                          style={{ color: "white" }}
                        />
                      ) : (
                        <>{isLastQuestion ? "Finalizar Case" : "Avançar"}</>
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      </Modal>

      <Dialog
        isOpen={isOpenLeaveDialog}
        icon={<InfoIcon color={Theme.colors.feedback.negative} />}
        text="Atenção! Você está saindo do case. Ao confirmar, todo o seu progresso atual será perdido."
        confirmButtonText="Continuar no Case"
        onConfirm={toggleLeaveDialog}
        closeButtonText="Sair do Case"
        onClose={onLeaveQuiz}
      />

      <Dialog
        isOpen={isTimeExpired}
        icon={<InfoIcon color={Theme.colors.feedback.negative} />}
        text="Tempo esgotado! 
        O tempo limite para esse questionário chegou
         ao fim. Mas não se preocupe, todas 
         as respostas respondidas anteriormente foram salvas."
        onConfirm={() => {
          onClose?.();
          setIsTimeExpired(false);
        }}
      />
      <Modal
        isOpen={showResumeCase}
        onClose={toggleResumeCase}
        containerProps={{ maxWidth: 817, width: "100%" }}
      >
        <Box
          flexDirection={"column"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          p={"1rem"}
          paddingLeft={{ xs: 0, sm: 2 }}
          width="95%"
        >
          <Box mb={2}>
            <Text fontSize="1rem" >
              {updatedChallenge?.title}
            </Text>
          </Box>
          <Card
            sx={{
              borderRadius: "0.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              border={"1px solid #E0E0E0"}
              borderRadius={4}
              p={1}
              width={"100%"}
            >
              <Text sx={{ justifyContent: "center", color: "#005EB8" }}>
                PARABÉNS VOCÊ COMPLETOU O CASE
              </Text>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Text fontSize={"0.8rem"} color={"#93999F"}>
                Pontos adquiridos:
              </Text>

              <Box
                sx={{
                  padding: "1rem",
                  border: "1px solid #005EB8",
                  borderRadius: 4,
                  display: "flex",
                }}
              >
                <Text fontSize={42} color="#005EB8">
                  {updatedChallenge?.points?.earnedPoints} pts{" "}
                </Text>
                <Divider orientation="vertical" flexItem />
                <Box
                  ml={2}
                  display="flex"
                  flexDirection="column"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text fontSize={6} color={"#93999F"}>
                    Pontuação máxima:
                  </Text>
                  <Text fontSize={17} color={"#93999F"}>
                    {updatedChallenge?.points?.maximumPoints} pts
                  </Text>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: "1rem", paddingBottom: "2rem" }}>
              <Text color={"#93999F"}>
                Respostas certas:{" "}
                <Text color={"#107048"}>
                  {updatedChallenge?.submission?.totalCorrect ?? 0}
                </Text>
              </Text>
              <Divider orientation="vertical" flexItem />
              <Text color={"#93999F"}>
                Respostas erradas:{" "}
                <Text color={"#BE3E37"}>
                  {(updatedChallenge?.questions?.length ?? 0) -
                    (updatedChallenge?.submission?.totalCorrect ?? 0)}
                </Text>
              </Text>
            </Box>
          </Card>

          <Box display="flex" width="100%" justifyContent="end" mt={2} pt={2}>
            <Button
              fullWidth={false}
              style={{ padding: "0.3rem", width: "8rem" }}
              onClick={() => {
                toggleResumeCase();
              }}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default memo(QuizQuestionsDialog);
