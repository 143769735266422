import { queryClient } from "@/services/react-query";
import { getUserInfo } from "@/services/users";
import { UserInfo } from "@/services/users/types/user.types";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoader } from "./LoaderContext";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/firebase.config";
import { useQuery } from "react-query";

type UserContext = {
  userInfo?: UserInfo;
  isLoadingUser: boolean;
  isAuthenticated?: boolean;
  logOut: () => void;
  refetchUserInfo: () => void;
};

const UserContext = createContext<UserContext>({} as UserContext);

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const navigate = useNavigate();
  const loader = useLoader();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

  useEffect(() => {
    loader.show("authState");

    const unsubscribe = onAuthStateChanged(auth, async (_user) => {
      if (_user) {
        setIsAuthenticated(true);
        loader.show("userInfo");
      } else {
        setIsAuthenticated(false);
      }
      loader.hide("authState");
    });

    return () => unsubscribe();
  }, []);

  const { data: userInfo, isLoading: isLoadingUser } = useQuery(
    ["user-info", isAuthenticated],
    () => getUserInfo(),
    {
      enabled: isAuthenticated === true,
      retry: 1,
      onSettled() {
        loader.hide("userInfo");
      },
    }
  );

  const logOut = () => {
    setIsAuthenticated(false);
    auth.signOut();

    navigate("/sign-in");

    queryClient.cancelQueries();
  };

  const refetchUserInfo = () => {
    queryClient.invalidateQueries({
      queryKey: "user-info",
      exact: false,
    });
  };

  return (
    <UserContext.Provider
      value={{
        userInfo,
        isLoadingUser,
        isAuthenticated,
        logOut,
        refetchUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContext => useContext(UserContext);
