import {
  BottomWrapper,
  CardImage,
  Container,
  ContentWrapper,
  ScoreWrapper,
} from "./styles";
import { CourseCardProps } from "./props.type";
import Text from "@/components/Text";
import Spacer from "@/components/Spacer";
import Theme from "@/utils/theme";
import { Box } from "@mui/material";
import { useCourseCard } from "./useCourseCard";
import Chip from "@/components/Chip";
import CardButton from "../CardButton";

const CourseCard = ({
  imageSrc,
  title,
  course,
  onClick,
  lockButton,
  courseEnded,
}: CourseCardProps) => {
  const { isPending, status } = useCourseCard({ course });

  return (
    <Container>
      <CardImage src={imageSrc} />

      <Spacer width="1.5rem" />
      <ContentWrapper>
        <Box px={{ xs: "1rem", sm: "0px" }}>
          <Box display="flex" alignItems="center">
            <Text fontSize={{ xs: "", sm: "1rem" }} flex={1}>
              {title}
            </Text>

            <Box mr="1rem" display={{ xs: "none", sm: "block" }}>
              <Chip label={status.text} color={status.color} />
            </Box>
          </Box>
        </Box>

        <Spacer height={"1rem"} />
        <BottomWrapper>
          <Box
            display="flex"
            flex={1}
            px={{ xs: "1rem", sm: "0px" }}
            justifyContent="space-between"
          >
            <CardButton
              isPending={isPending}
              onClick={onClick}
              lockButton={lockButton}
              courseEnded={courseEnded}
            />

            <Box display={{ xs: "block", sm: "none" }}>
              <Spacer grow />
              <Chip label={status.text} color={status.color} />
            </Box>
          </Box>

          <Spacer height={"1.5rem"} />
          <ScoreWrapper>
            <Text>
              Pontuação da prova:{" "}
              <Text color={Theme.colors.brand.secondary}>
                {course?.examPoints ?? "-"}{" "}
              </Text>
            </Text>

            <Text> | Pontuação do curso: {course?.coursePoints ?? "-"}</Text>
          </ScoreWrapper>
        </BottomWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default CourseCard;
