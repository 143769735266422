import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_KEY,
};

const app = initializeApp(firebaseConfig);

const firebaseAuth = getAuth(app);
firebaseAuth.languageCode = "pt-BR";

export const auth = firebaseAuth;
