import Theme from "@/utils/theme";
import { useMemo } from "react";

export const useResendCodeButton = (disabled?: boolean) => {
  const color = useMemo(() => {
    if (disabled) return Theme.colors.text.tertiary;

    return Theme.colors.feedback.info;
  }, [disabled]);

  return { color };
};
