import { memo } from "react";
import {
  BottomWrapper,
  CardImage,
  Container,
  ContentWrapper,
  CounterWrapper,
} from "./styles";
import { InfoCardProps } from "./props.type";
import Text from "@/components/Text";
import Spacer from "@/components/Spacer";
import Theme from "@/utils/theme";
import CardButton from "./components/CardButton";
import { Box } from "@mui/material";

const InfoCard = ({
  imageSrc,
  title,
  description,
  buttonCta,
  completedCounter,
  onClick,
  lockButton,
}: InfoCardProps) => {
  return (
    <Container>
      <CardImage src={imageSrc} />

      <Spacer width="1.5rem" />
      <ContentWrapper>
        <Box px={{ xs: "1rem", sm: "0px" }}>
          <Box display="flex" mt={{ xs: "0.5rem", sm: "1rem" }}>
            <Text fontSize={{ xs: "", sm: "1.5rem" }} flex={1}>
              {title}
            </Text>
          </Box>

          <Spacer height="1rem" />
          <Text color={Theme.colors.text.tertiary} fontSize="1rem">
            {description}
          </Text>
        </Box>

        <Spacer height="1.5rem" />
        <BottomWrapper>
          <Box display="flex" flex={1} justifyContent="space-between">
            <CardButton
              label={buttonCta}
              onClick={onClick}
              lockButton={lockButton}
            />
          </Box>

          <CounterWrapper>
            <Text>
              Você Concluiu:{" "}
              <Text color={Theme.colors.brand.secondary}>
                {completedCounter}
              </Text>
            </Text>
          </CounterWrapper>
        </BottomWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default memo(InfoCard);
