import { useMemo } from "react";
import { CourseCardProps } from "./props.type";
import { CourseStatus } from "@/services/courses/types/course.types";
import Theme from "@/utils/theme";

export const useCourseCard = ({ course }: Partial<CourseCardProps>) => {
  const isPending = useMemo(() => {
    return course?.status === CourseStatus.PENDING;
  }, [course?.status]);

  const status = useMemo(() => {
    if (!course?.courseUrl) {
      return {
        text: "Aguarde liberação",
        color: Theme.colors.neutral.mid_dark,
      };
    }

    if (isPending) {
      return {
        text: "Pendente",
        color: Theme.colors.feedback.warning,
      };
    }

    if (course?.status === CourseStatus.IN_PROGRESS) {
      return {
        text: "Em Progresso",
        color: Theme.colors.feedback.info,
      };
    }

    return {
      text: "Concluído",
      color: Theme.colors.feedback.positive,
    };
  }, [course, isPending]);

  return { isPending, status };
};
